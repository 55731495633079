<template>
  <a-skeleton v-if="detailLoading" />
  <div v-else-if="listData != null">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="客户信息">
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
          <div class="ic-title">基本信息</div>
          <a-row>
            <a-col :span="12">
              <a-form-item label="所属机构">
                <span class="detail-text">{{ listInstitutionsData.name }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="客户姓名">
                <span class="detail-text">{{ listData.name }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="来源渠道">
                <span class="detail-text">{{ listData.sourceChannel }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="微信号">
                <span class="detail-text">{{ listData.wechat }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="手机号">
                <span class="detail-text">{{ listData.phoneNumber }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="咨询项目">
                <span class="detail-text">{{ listData.consultingProject }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="意向等级">
                <a-tag color="orange" v-if="listData.intentionLevel === 'HIGH'">高</a-tag>
                <a-tag color="blue" v-else-if="listData.intentionLevel === 'MEDIUM'">中</a-tag>
                <a-tag color="red" v-else-if="listData.intentionLevel === 'LOW'">低</a-tag>
                <a-tag color="red" v-else-if="listData.intentionLevel === 'INVALID'">无效</a-tag>
              </a-form-item>

            </a-col>
            <a-col :span="12">
              <a-form-item label="意向国家">
                <span class="detail-text">{{ listData.intendedCountries }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="预算（人名币）">
                <span class="detail-text">{{ listData.budget ? `￥${listData.budget} 万` : '无' }}</span>
              </a-form-item>

            </a-col>
            <a-col :span="12">
              <a-form-item label="性别">
                <span class="detail-text">{{ listData.gender == 'FEMALE' ? '女' : (listData.gender == 'MALE' ? '男' : '未知')
                }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="常驻城市">
                <span class="detail-text">{{ listData.residentCity }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="最高学历">
                <span class="detail-text">{{ listData.highestDegree }}</span>
              </a-form-item>

            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="就读院校">
                <span class="detail-text">{{ listData.college }}</span>
              </a-form-item>

            </a-col>
            <a-col :span="12">
              <a-form-item label="就读专业">
                <span class="detail-text">{{ listData.studyProfessional }}</span>
              </a-form-item>

            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="GPA">
                <span class="detail-text">{{ listData.gpa }}</span>
              </a-form-item>

            </a-col>
            <a-col :span="12">
              <a-form-item label="语言成绩类型">
                <span class="detail-text">{{ listData.languageGradeType }}</span>
              </a-form-item>

            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="语言成绩">
                <span class="detail-text">{{ listData.languageAchievement }}</span>
              </a-form-item>

            </a-col>
            <a-col :span="12">
              <a-form-item label="毕业时间">
                <span class="detail-text">{{ listData.graduationTime && listData.graduationTime.split('T')[0] }}</span>
              </a-form-item>

            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="客户星级">
                <span class="detail-text">{{ getStar(listData.star) }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item label="其他信息" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                <span class="detail-text">{{ listData.remarks }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
          <div class="ic-title">客户关系</div>
          <a-row>
            <a-col :span="12">
              <a-form-item label="创建人">
                <span class="detail-text">{{ creator ? creator.name : '留服搜索' }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="归属人">
                <span class="detail-text">{{ attributablePerson ? attributablePerson.name : '无' }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="跟进人">
                <span class="detail-text">{{ followUpPeople ? followUpPeople.name : '无' }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="客户状态">
                <span class="detail-text">{{ getValue(listData.customerStatus) }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>

      <a-tab-pane key="2" tab="跟进记录" force-render>
        <table-page :config="config" />
      </a-tab-pane>

    </a-tabs>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { get } from "@/utils/http";
import { customerStatus, starRating } from '@/utils/public';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      detailLoading: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      listData: null,
      listInstitutionsData: null,
      attributablePerson: null,
      creator: null,
      followUpPeople: null,
      activeKey: "1",
      stars: starRating,
      config: {
        dataUrl: `/agency/highSeas/followUpRecords?id=${this.selectedRows[0].id}`,
        filter: {
          controls: []
        },

        /* 表格操作 */
        actions: [
        ],

        columns: [
          // {
          //   key: 'id',
          //   title: '客户id',
          //   fixed: 'left',
          // },
          {
            key: 'institutionsCustomerRelationship.name',
            title: '客户姓名',
          },
          {
            key: 'followUpTime',
            title: '跟进时间',
            type: 'datetime'
          },
          {
            key: 'followUpPeople.name',
            title: '跟进人',
          },
          {
            key: 'followUpContent',
            title: '跟进内容',
            // width: '200px',
            // ellipsis: true
          },
          {
            key: 'nextFollowUpTime',
            title: '下次跟进时间',
            type: 'datetime'
          },
          {
            key: 'createdDate',
            title: '记录创建时间',
            type: 'datetime'
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: ['id'],
          /* 默认排序 */
          defaultSort: '-id'
        },

        /* 表格行操作 */
        rowActions: []
      }
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  methods: {
    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/agency/highSeas/details", { id: id });
        this.attributablePerson = res.attributablePerson;
        this.creator = res.creator;
        this.followUpPeople = res.followUpPeople;
        this.listData = res.institutionsCustomerRelationship;
        this.listInstitutionsData = res.institutions;
        this.userLabel = res.userLabel && res.userLabel.map((item) => {
          return item.content
        });
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    getValue(val) {
      let data = customerStatus.find(item => {
        return item.value == val
      });
      return data.text || '无'
    },
    getStar(val) {
      if (val) {
        let dts = this.stars.filter(item => {
          return item.value === val
        })
        return dts[0].text || '-'
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}

.detail-text {}

.ant-col {
  margin-top: 0;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}

.ic-title {
  font-size: 17px;
  font-weight: bold;
  padding: 20px 0 20px 20px;
}
</style>